import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

// Functions
import { deleteRank as deleteRankApi, getAllRanks, getOneRank, getRanksNoPagination } from "@api/";

// Constants
import { KEY_RANK_GET_ALL, KEY_RANKS_NO_PAGINATION } from "@constants/";

// Utils
import { error, success } from "@utils/";

// Hooks
import { useInvalidate } from "@hooks/";

// Enums
import { EFilters } from "@enums/";

// Type
import type { TSearch } from "@_types/";

export const useGetAllRanks = ({ search, page, per_page }: TSearch) => {
  const rankPayload = useMemo(() => {
    return {
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
      },
    };
  }, [page, per_page, search]);

  const {
    data: ranks,
    isLoading: isRankLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_RANK_GET_ALL, rankPayload], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getAllRanks(rankPayload), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true,
  });

  return { ranks, isLoading: isRankLoading || isFetching };
};

export const useGetOneRank = ({ id }: { id: string | undefined }) => {
  const {
    data: rank,
    isLoading: isRankLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_RANK_GET_ALL, id],
    queryFn: () => getOneRank(id!),
    initialData: [],
    enabled: !!id,
  });

  return { rank, isLoading: isRankLoading || isFetching };
};

export const useDeleteRank = () => {
  const { invalidate } = useInvalidate();

  const deleteRank = async (data: { id: string }) => {
    try {
      const response = await deleteRankApi(data?.id);
      if (response) {
        success({ message: response?.data?.message });
        invalidate(KEY_RANK_GET_ALL);
      }
    } catch (err) {
      error({ title: "Something went wrong" });
    }
  };

  return { deleteRank };
};

export const useGetRanksNoPagination = () => {
  const {
    data: ranks,
    isLoading: isRankLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_RANKS_NO_PAGINATION], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getRanksNoPagination(), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true,
  });

  return { ranks, isRankLoading: isRankLoading || isFetching };
};
