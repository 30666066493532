import React from "react";
import ReactDOM from "react-dom/client";

// React Query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Router
import { BrowserRouter } from "react-router-dom";

// Mantine
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

// Main App
import { App } from "./App.tsx";

// Utils
import { theme } from "@utils/";

// Contexts
import { AuthProvider, UserProvider } from "@contexts/";

// Layouts
import { ErrorWrapper } from "@layouts/";

// CSS
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

import "./index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // @ts-expect-error unknown types - no docs
      retry: (_times, err) => {
        // @ts-expect-error unknown types - no docs
        if (err?.response?.status === 401) {
          return false; // do not retry, trigger error
        }
      },
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MantineProvider theme={theme} classNamesPrefix="zenon" defaultColorScheme="light">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ErrorWrapper>
            <AuthProvider>
              <UserProvider>
                <App />
              </UserProvider>
            </AuthProvider>
            <Notifications limit={5} />
          </ErrorWrapper>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>,
);
