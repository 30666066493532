import { showNotification } from "@mantine/notifications";

export const success = ({ title = "Success!", message = "" }) => {
  showNotification({
    withCloseButton: true,
    autoClose: 3000,
    title: title,
    message: message,
    loading: false,
  });
};
