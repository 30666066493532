import { Skeleton } from "@mantine/core";

const FormSkeleton = () => {
  return (
    <div className="h-full w-full px-md">
      <Skeleton height={50} mt={10} radius="md" />
      <div className="mt-10 flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="20%" />
        <Skeleton height={40} mt={10} radius="md" width="20%" />
        <Skeleton height={40} mt={10} radius="md" width="20%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="41%" />
        <Skeleton height={40} mt={10} radius="md" width="41%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="41%" />
        <Skeleton height={40} mt={10} radius="md" width="41%" />
      </div>{" "}
      <Skeleton height={40} mt={10} radius="md" width="41%" />
      <Skeleton height={40} mt={10} radius="md" width="41%" />
      <Skeleton height={100} mt={10} radius="md" width="41%" />
    </div>
  );
};

export { FormSkeleton };
