const KEY_FETCH_USER = "get_user";

//FOLDERS
const KEY_FOLDER_GET_ALL = "get-all-from-folder";

// BENEFITS
const KEY_GET_ALL_BENEFITS_FOLDERS = "get-all-benefits-folders";
const KEY_BENEFIT_GET_ALL = "get-all-benefits";
const KEY_BENEFIT_GET_ONE = "get-one-benefit";
const KEY_BENEFITS_GET_ALL_NO_PAGINATION = "get-all-benefits-no-pagination";

// REQUIREMENTS
const KEY_GET_ALL_REQUIREMENTS_FOLDERS = "get-all-requirements-folders";
const KEY_REQUIREMENTS_GET_ALL = "get-all-requirements";
const KEY_REQUIREMENT_GET_ONE = "get-one-requirement";
const KEY_REQUIREMENTS_GET_ALL_NO_PAGINATION = "get-all-requirements-no-pagination";

// RANKS
const KEY_RANK_GET_ALL = "get-all-ranks";
const KEY_RANK_GET_ONE = "get-one-rank";
const KEY_RANKS_NO_PAGINATION = "get-all-ranks-no-pagination";

// CONFIGURATION
const KEY_CONFIGURATION_GET_ALL = "get-all-configuration";
const KEY_CONFIGURATION_GET_ALL_SELECTED = "get-all-configuration-selected";

// CLASSIFICATIONS
const KEY_CLASSIFICATION_GET_ALL = "get-all-classifications";

// CUSTOMERS
const KEY_CUSTOMERS_NO_PAGINATION = "get-all-customers-no-pagination";

export {
  KEY_BENEFIT_GET_ALL,
  KEY_BENEFIT_GET_ONE,
  KEY_FETCH_USER,
  KEY_FOLDER_GET_ALL,
  KEY_GET_ALL_BENEFITS_FOLDERS,
  KEY_GET_ALL_REQUIREMENTS_FOLDERS,
  KEY_BENEFITS_GET_ALL_NO_PAGINATION,
  KEY_REQUIREMENTS_GET_ALL,
  KEY_REQUIREMENT_GET_ONE,
  KEY_REQUIREMENTS_GET_ALL_NO_PAGINATION,
  KEY_RANK_GET_ALL,
  KEY_RANK_GET_ONE,
  KEY_CONFIGURATION_GET_ALL,
  KEY_CONFIGURATION_GET_ALL_SELECTED,
  KEY_CLASSIFICATION_GET_ALL,
  KEY_CUSTOMERS_NO_PAGINATION,
  KEY_RANKS_NO_PAGINATION,
};
