// Utils
import { api, removeEmptyFromObject } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

// Types
import { TFolderFilter } from "@_types/";

// Enums
import { EEntity } from "@enums/";

const getAllBenefitsFromFolder = async ({
  folderId,
  filters,
}: {
  folderId: string | undefined;
  filters?: TFolderFilter[] | TFolderFilter;
}) => {
  const data = removeEmptyFromObject({
    folder_id: folderId,
    ...filters,
  });

  const response = await api.get(API_ROUTES.benefits, data);
  return response?.data;
};

const getAllBenefitsFolder = async ({ filters }: { filters?: TFolderFilter[] | TFolderFilter }) => {
  const data = removeEmptyFromObject({
    folder_type: EEntity.BENEFITS,
    ...filters,
  });

  const response = await api.get(API_ROUTES.folders, data);
  return response?.data;
};

const getBenefitsNoPagination = async () => {
  const response = await api.get(`${API_ROUTES.benefits}/all`, {});
  return response?.data;
};

const getOneBenefit = async (id: string) => {
  const response = await api.get(`${API_ROUTES.benefits}/${id}`, {});
  return response?.data;
};

const createBenefit = async (data: unknown) => {
  const response = await api.post(API_ROUTES.benefits, {}, data);
  return response?.data;
};

const updateBenefit = async (id: string, data: unknown) => {
  const response = await api.patch(`${API_ROUTES.benefits}/${id}`, {}, data);
  return response?.data;
};

const deleteBenefit = async (id: string) => {
  const response = await api.destroy(`${API_ROUTES.benefits}/${id}`);
  return response;
};

export {
  createBenefit,
  deleteBenefit,
  getAllBenefitsFolder,
  getAllBenefitsFromFolder,
  getOneBenefit,
  updateBenefit,
  getBenefitsNoPagination,
};
