import { AppShell as MantineAppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

// Types
import type { ReactNode } from "react";

// Layouts
import { Header, Sidebar } from "@layouts/";

type AppShellProps = {
  children: ReactNode;
};

const AppShell = ({ children }: AppShellProps) => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <MantineAppShell
      className="bg-offwhite"
      header={{ height: 60 }}
      navbar={{
        width: 250,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
    >
      <Header opened={opened} toggle={toggle} />

      <Sidebar />

      <MantineAppShell.Main>{children}</MantineAppShell.Main>
    </MantineAppShell>
  );
};

export { AppShell };
