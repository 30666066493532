import { useQuery } from "@tanstack/react-query";

// Hooks
import { useAuth } from "@hooks/";

// Constants
import { KEY_FETCH_USER } from "@constants/";

// Requests
import { fetchUser } from "@api/";

// Interfaces
import { IUser } from "@interfaces/";

export const initialUser: IUser = {
  id: 0,
  name: "",
  role_id: "",
  email: "",
};

export const useGetUser = () => {
  const { isAuthenticated, id } = useAuth();
  return useQuery({
    queryKey: [KEY_FETCH_USER, id],
    queryFn: async () => await fetchUser(id!),
    enabled: isAuthenticated && !!id,
    initialData: initialUser,
  });
};
