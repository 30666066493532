// Utils
import { api } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

const fetchUser = async (id: string) => {
  const response = await api.get(`${API_ROUTES.user}/${id}`, {});
  return response?.data;
};

export { fetchUser };
