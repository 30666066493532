function getInitials(str: string) {
  // Split the string by space
  const parts = str.split(" ");

  // Check if we have exactly 2 parts
  if (parts.length === 2) {
    const firstName = parts[0];
    const lastName = parts[1];

    return { firstName, lastName };
  } else {
    return { firstName: "E", lastName: "E" };
  }
}

export { getInitials };
