/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

// Constants
import { BASE_URL, LS_TOKEN } from "@constants/";

// Utils
import { error, getItem, removeItem } from "@utils/";

const errorHandling = (requestError: any) => {
  if (requestError.response) {
    switch (requestError.response.status) {
      case 401:
        error({ title: "401 Error | Unauthenticated" });
        setTimeout(() => {
          removeItem(LS_TOKEN);
        }, 1700);
        window.location.reload();
        break;
      case 422:
        Object.values(requestError.response.data.errors).forEach((message) => {
          error({
            message: (message as any)?.[0] ?? "Unknown error",
          });
        });

        break;
      case 429:
        error({ title: "429 Error | Too many requests" });
        break;
      case 500:
        error({ title: "500 Error | Internal Server error" });
        break;
      default:
        break;
    }
  }
};

const urlEncoding = (paramaters: any) => {
  const encodedParamaters = new URLSearchParams();
  for (const key in paramaters) {
    encodedParamaters.append(`${key}`, `${paramaters[key]}`);
  }
  return encodedParamaters;
};

const get = async (url: string, paramaters: any) => {
  const token = getItem(LS_TOKEN);
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const encodedParamaters = urlEncoding(paramaters);
  try {
    const response = await axios.get(`${BASE_URL}${url}`, {
      params: encodedParamaters,
      headers: headers,
    });
    return response;
  } catch (error) {
    errorHandling(error);
  }
};

const put = async (url: string, paramaters: any, data: any) => {
  const token = getItem(LS_TOKEN);
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const encodedParamaters = urlEncoding(paramaters);

  try {
    const response = await axios.put(`${BASE_URL}${url}`, data, {
      params: encodedParamaters,
      headers: headers,
    });
    return response;
  } catch (error) {
    errorHandling(error);
  }
};

const patch = async (url: string, paramaters: any, data: any) => {
  const token = getItem(LS_TOKEN);
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const encodedParamaters = urlEncoding(paramaters);

  try {
    const response = await axios.patch(`${BASE_URL}${url}`, data, {
      params: encodedParamaters,
      headers: headers,
    });
    return response;
  } catch (error) {
    errorHandling(error);
  }
};

const post = async (url: string, paramaters: any, data: any) => {
  const token = getItem(LS_TOKEN);
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const encodedParamaters = urlEncoding(paramaters);

  try {
    const response = await axios.post(`${BASE_URL}${url}`, data, {
      params: encodedParamaters,
      headers: headers,
    });
    return response;
  } catch (error) {
    errorHandling(error);
  }
};

const destroy = async (url: string) => {
  const token = getItem(LS_TOKEN);
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.delete(`${BASE_URL}${url}`, {
      headers: headers,
    });
    return response;
  } catch (error) {
    errorHandling(error);
  }
};

export const api = {
  get,
  put,
  post,
  patch,
  destroy,
};
