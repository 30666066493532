import { clsx } from "clsx";
import { useNavigate } from "react-router-dom";

// Constants
import { CLIENT_ROUTES } from "@constants/";

type LogoProps = {
  size?: string;
  disableLink?: boolean;
};

const Logo = ({ size = "text-2xl", disableLink }: LogoProps) => {
  const navigate = useNavigate();
  return (
    <h1
      onClick={() => {
        if (disableLink) return;
        navigate(CLIENT_ROUTES.root);
      }}
      className={clsx(
        "text-braytron-textDark block !font-domyouji",
        disableLink ? "cursor-default" : "cursor-pointer",
        size,
      )}
    >
      BraytronAI
    </h1>
  );
};

export { Logo };
