export enum EClassificationType {
  NO_ORDER = 1,
  HIERARCHY = 2,
}
export const allClassificationTypes = [
  {
    label: "No order",
    value: EClassificationType.NO_ORDER.toString(),
  },
  {
    label: "Hierarchy",
    value: EClassificationType.HIERARCHY.toString(),
  },
];
