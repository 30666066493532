import { LoadingOverlay as MantineLoadingOverlay } from "@mantine/core";

const LoadingOverlay = ({ visible }: { visible: boolean }) => {
  return (
    <MantineLoadingOverlay visible={visible} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }}>
      LoadingOverlay
    </MantineLoadingOverlay>
  );
};

export { LoadingOverlay };
