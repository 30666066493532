import { API_ROUTES } from "@constants/";
import { api, removeEmptyFromObject } from "@utils/";
//Types
import { TFolderFilter } from "@_types/";

const getAllFromFolder = async ({
  folderId,
  filters,
}: {
  folderId: string;
  filters?: TFolderFilter[] | TFolderFilter;
}) => {
  const data = removeEmptyFromObject({
    folder_id: folderId,
    ...filters,
  });

  const response = await api.get(API_ROUTES.benefits, data);
  return response?.data;
};

const updateFolder = async (id: string, data: unknown) => {
  const response = await api.patch(`${API_ROUTES.folders}/${id}`, {}, data);
  return response?.data;
};

export { getAllFromFolder, updateFolder };
