// MARK: Imports
import { TextInput } from "@mantine/core";

// Components
import { CustomTable, Filters, PageHeader, TableSkeleton } from "@components/";

// Requests

// Constants
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PER_PAGE,
  DEFAULT_TOTAL,
  DEFAULT_TOTAL_PAGES,
  RANKS_TABLE_HEADER,
} from "@constants/";

// Enums
import { EEntity, EFilters, EPagination, EScheduleType } from "@enums/";
import { useDebouncedState } from "@mantine/hooks";
import { useState, useMemo, useCallback, useEffect } from "react";
import { useDeleteRank, useGetAllRanks } from "@api/";

const AllRanks = () => {
  // MARK: State
  const [search, setSearch] = useDebouncedState("", DEFAULT_DEBOUNCE_TIME);
  const [paginator, setPaginator] = useState({
    [EPagination.PER_PAGE]: DEFAULT_PER_PAGE,
    [EPagination.CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    [EPagination.TOTAL]: DEFAULT_TOTAL,
    [EPagination.TOTAL_PAGES]: DEFAULT_TOTAL_PAGES,
  });

  const searchValue = useMemo(() => {
    return search;
  }, [search]);

  // MARK: Functions
  const { deleteRank } = useDeleteRank();
  const { ranks, isLoading } = useGetAllRanks({
    [EFilters.SEARCH]: searchValue,
    [EFilters.PAGE]: paginator[EPagination.CURRENT_PAGE],
    [EFilters.PER_PAGE]: paginator[EPagination.PER_PAGE],
  });

  const ranksData = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ranks?.data?.map((item: any) => {
      return {
        ...item,
        numberOfBenefits: item?.benefits?.length,
        numberOfRequirements: item.requirements.length,
        scheduleType: EScheduleType[item.schedule_type],
      };
    });
  }, [ranks]);

  const onPageChange = (newPage: number) => {
    setPaginator((prev) => {
      return { ...prev, [EPagination.CURRENT_PAGE]: newPage };
    });
  };

  const resetFilters = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  // MARK: Effects
  // Set initial pagination from backend
  useEffect(() => {
    if (!ranks || ranks?.length === 0) return;
    setPaginator(ranks?.pagination);
  }, [ranks]);

  // MARK: Renders
  if (isLoading) return <TableSkeleton />;

  return (
    <section>
      <PageHeader showCreateBtn title="Ranks" entity={EEntity.RANKS} />

      <Filters onReset={resetFilters}>
        <form>
          <TextInput
            defaultValue={search}
            onChange={(event) => setSearch(event?.currentTarget?.value)}
            placeholder="Search by rank name..."
            variant="unstyled"
          />
        </form>
      </Filters>

      <div className="w-full">
        <CustomTable
          centerFields
          columns={RANKS_TABLE_HEADER}
          entity={EEntity.RANKS}
          handleDelete={deleteRank}
          onPageChange={onPageChange}
          showDelete
          showEdit
          tableData={ranksData}
          paginator={ranks.pagination}
          hasFolderStructure={false}
        />
      </div>
    </section>
  );
};

export { AllRanks };
