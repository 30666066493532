import { Skeleton } from "@mantine/core";

const TableSkeleton = () => {
  return (
    <div className="h-full w-full px-md">
      <Skeleton height={50} mt={10} radius="md" />
      <Skeleton height={40} mt={20} radius="md" />

      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
        <Skeleton height={40} mt={10} radius="md" width="30%" />
      </div>
      <div className="flex w-full gap-x-3">
        <Skeleton height={40} mt={10} radius="md" width="10%" />
        <Skeleton height={40} mt={10} radius="md" width="80%" />
        <Skeleton height={40} mt={10} radius="md" width="10%" />
      </div>
    </div>
  );
};

export { TableSkeleton };
