import { UserContext } from "@contexts/";
import { useContext } from "react";

export function useUser() {
  const { user, isLoading } = useContext(UserContext);

  if (!user) throw new Error("User Context is undefined");

  return { user, isLoading };
}
