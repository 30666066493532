import { Center, Flex } from "@mantine/core";

// Components
import { Logo } from "@components/";

// Enums
import { ESize } from "@enums/";

const Home = () => {
  return (
    <Center h="80dvh">
      <Flex gap={ESize.XS}>
        <Logo />
      </Flex>
    </Center>
  );
};

export { Home };
