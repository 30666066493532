import { Route, Routes } from "react-router-dom";

import { NotFound } from "@pages/";
import { AddRank } from "./AddRank";
import { AllRanks } from "./AllRanks";
import { EditRank } from "./EditRank";

const RanksRoutes = () => {
  return (
    <Routes>
      <Route index element={<AllRanks />} />
      <Route path="/add" element={<AddRank />} />
      <Route path="/edit/:id" element={<EditRank />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { RanksRoutes };
