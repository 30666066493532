import { Modal, Stack } from "@mantine/core";
import { useMemo } from "react";

// Enums
import { EEntity, ESize } from "@enums/";

type PreviewEntityModalProps = {
  entity: EEntity;
  opened: boolean;
  close: () => void;
};

const PreviewEntityModal = ({ entity, opened, close }: PreviewEntityModalProps) => {
  const renderContent = useMemo(() => {
    switch (entity) {
      case EEntity.BENEFITS:
        return <p>BENEFITS</p>;
      case EEntity.CLASSIFICATION:
        return <p>CLASSIFICATION</p>;
      case EEntity.RANKS:
        return <p>RANKS</p>;
      case EEntity.REQUIREMENTS:
        return <p>REQUIREMENTS</p>;
      default:
        break;
    }
  }, [entity]);

  return (
    <Modal radius={ESize.MD} centered size={ESize.MD} opened={opened} onClose={close} title="Preview">
      <Stack gap={ESize.MD}>{renderContent}</Stack>
    </Modal>
  );
};

export { PreviewEntityModal };
