/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const convertWithTimeToDayMonthYear = (date: Date) => {
  return dayjs(date).format("DD.MM.YYYY HH:mm");
};

const revertFromDayMonthYear = (date: any) => {
  return dayjs(date, "DD.MM.YYYY HH:mm").toDate();
};

export { convertWithTimeToDayMonthYear, revertFromDayMonthYear };
