/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Pagination, Table, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Check, CopyPlus, Eye, Pencil, X } from "lucide-react";
import { Fragment } from "react/jsx-runtime";

// Hooks
import { useNavigate } from "react-router-dom";

// Components
import { ConfirmPopup, Icon, PreviewEntityModal, Visible } from "@components/";

// Types
import { TPaginator } from "@_types/";

// Enums
import { EEntity } from "@enums/";

// Utils
import { getLinkBasedOnEntity } from "@utils/";
import clsx from "clsx";

type TTableBodyProps = {
  entity: EEntity;
  tableData: unknown[];
  paginator: TPaginator;
  columns: TColumnProps[];
  showCopy?: boolean;
  showEdit?: boolean;
  centerFields?: boolean;
  showDelete?: boolean;
  showPreview?: boolean;
  hasFolderStructure?: boolean;
  handleDelete?: (arg0: any) => void;
  handleProxy?: (arg0: any) => void;
  onPageChange?: (arg0: number) => void;
};

type TColumnProps = {
  header: string;
  field: string;
  custom?: {
    type: string;
    tooltip: string;
    icon: any;
  };
};

const renderField = (field: any) => {
  switch (typeof field) {
    case "boolean":
      return field ? <Check className="mx-auto h-4 w-4" /> : <X className="mx-auto h-4 w-4" />;
    case "number":
    case "string":
    default:
      return field ? field : "-";
  }
};

const CustomTable = ({
  centerFields = false,
  columns,
  entity,
  handleDelete,
  handleProxy,
  onPageChange,
  paginator,
  showCopy,
  showDelete,
  showEdit,
  showPreview,
  tableData,
  hasFolderStructure = false,
}: TTableBodyProps) => {
  const navigate = useNavigate();
  const [isPreviewOpen, { open: openPreview, close: closePreview }] = useDisclosure();

  const goToEdit = (folderId: string, entityId: string) => {
    if (hasFolderStructure) return `/${getLinkBasedOnEntity(entity)}/folder/${folderId}/edit/${entityId}`;
    return `/${getLinkBasedOnEntity(entity)}/edit/${entityId}`;
  };

  // If there is no tableData
  if (!tableData || tableData.length === 0)
    return (
      <section className="w-full p-md pt-xl">
        <Table className="bg-white p-md">
          <Table.Thead>
            <Table.Tr>
              {columns.map((col: TColumnProps) => {
                return <Table.Th key={col.field}>{col.header}</Table.Th>;
              })}

              <Visible when={!!showDelete || !!showEdit}>
                <Table.Th className="w-20">Actions</Table.Th>
              </Visible>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            <Table.Tr className="h-16 px-4">
              <Table.Td colSpan={columns.length}>
                <i className="text-braytron-darkGray/80">No data to display</i>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </section>
    );

  // If table data exists
  return (
    <section className="w-full p-md pt-xl">
      <Table
        verticalSpacing="sm"
        striped
        highlightOnHover
        className={clsx("bg-white p-md", centerFields && "!text-center")}
        withRowBorders={false}
      >
        {/* Table header */}
        <Table.Thead>
          <Table.Tr>
            {columns.map((col: TColumnProps) => {
              return (
                <Table.Th key={col.field} className={clsx(centerFields && "text-center")}>
                  {col.header}
                </Table.Th>
              );
            })}
            <Visible when={!!showDelete || !!showEdit}>
              <Table.Th className={clsx("w-20", centerFields && "text-center")}>Actions</Table.Th>
            </Visible>
          </Table.Tr>
        </Table.Thead>
        {/* Table body */}
        <Table.Tbody>
          {tableData.map((rowData: any) => {
            return (
              <Fragment key={rowData.id}>
                <Table.Tr>
                  {columns.map((col: TColumnProps) => {
                    return (
                      <Fragment key={col.field}>
                        {!col.custom && <Table.Td className="px-4 py-3">{renderField(rowData[col.field])}</Table.Td>}

                        {/* CUSTOM COL - BUTTON */}
                        {col.custom && (
                          <Table.Td className="px-4 py-3">
                            {col.custom.type === "button" && (
                              <Tooltip label={col.custom.tooltip} color="gray">
                                <Button
                                  className="group"
                                  variant="transparent"
                                  size="compact-sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (handleProxy) {
                                      handleProxy(rowData);
                                    }
                                  }}
                                >
                                  <Icon
                                    name={col?.custom?.icon}
                                    className="h-4 w-4 text-braytron-textDark group-hover:text-braytron-80"
                                  />
                                </Button>
                              </Tooltip>
                            )}
                          </Table.Td>
                        )}
                      </Fragment>
                    );
                  })}
                  <Visible when={!!showDelete || !!showEdit || !!showPreview || !!showCopy}>
                    <Table.Td className="w-48">
                      <Visible when={!!showCopy}>
                        <Tooltip label="Copy and Create" color="gray">
                          <Button
                            className="group"
                            variant="transparent"
                            size="compact-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              openPreview();
                            }}
                          >
                            <CopyPlus className="h-4 w-4 text-braytron-textDark group-hover:text-braytron-80" />
                          </Button>
                        </Tooltip>
                      </Visible>
                      <Visible when={!!showPreview}>
                        <Tooltip label="Preview" color="gray">
                          <Button
                            className="group"
                            variant="transparent"
                            size="compact-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              openPreview();
                            }}
                          >
                            <Eye className="h-4 w-4 text-braytron-textDark group-hover:text-braytron-80" />
                          </Button>
                        </Tooltip>
                      </Visible>
                      <Visible when={!!showEdit}>
                        <Tooltip label="Edit" color="gray">
                          <Button
                            className="group"
                            variant="transparent"
                            size="compact-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(goToEdit(rowData?.folder_id, rowData?.id));
                            }}
                          >
                            <Pencil className="h-4 w-4 text-braytron-textDark group-hover:text-braytron-80" />
                          </Button>
                        </Tooltip>
                      </Visible>
                      <Visible when={!!showDelete}>
                        <ConfirmPopup
                          title="Are you sure you want to delete this item?"
                          handleDelete={(event) => {
                            event.stopPropagation();
                            if (handleDelete) {
                              handleDelete(rowData);
                            }
                          }}
                        />
                      </Visible>
                    </Table.Td>
                  </Visible>
                </Table.Tr>
              </Fragment>
            );
          })}
        </Table.Tbody>
        <Table.Tfoot className="border-t border-braytron-lightGray">
          <Table.Tr>
            <Table.Td colSpan={columns.length + 1}>
              <div className="flex w-full items-center justify-center bg-white">
                <Pagination
                  className="pt-2"
                  value={paginator?.current_page}
                  onChange={(value: number) => {
                    onPageChange && onPageChange(value);
                  }}
                  total={paginator?.total_pages}
                />
              </div>
            </Table.Td>
          </Table.Tr>
        </Table.Tfoot>
      </Table>
      <PreviewEntityModal entity={entity} opened={isPreviewOpen} close={closePreview} />
    </section>
  );
};
export { CustomTable };
