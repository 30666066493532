import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { useState, useMemo, useCallback, useEffect } from "react";
import { Button, TextInput } from "@mantine/core";
import { Plus } from "lucide-react";

// Components
import { CustomTable, Filters, PageHeader, TableSkeleton } from "@components/";
import { AddFavoriteConfiguration } from "./AddFavoriteConfiguration";

// Utils
import { success, api, error } from "@utils/";

// Hooks
import { useInvalidate } from "@hooks/";

// Enum
import { EEntity, EFilters, EPagination } from "@enums/";

// Constants
import {
  DEFAULT_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_TOTAL,
  DEFAULT_TOTAL_PAGES,
  DEFAULT_DEBOUNCE_TIME,
  CONFIGURATION_TABLE_HEADER,
  API_ROUTES,
  KEY_CONFIGURATION_GET_ALL_SELECTED,
} from "@constants/";

// Api
import { useGetSelectedConfiguration } from "@api/";

const Configuration = () => {
  // MARK: State
  const { invalidate } = useInvalidate();
  const [search, setSearch] = useDebouncedState("", DEFAULT_DEBOUNCE_TIME);
  const [opened, { open, close }] = useDisclosure(false);
  const [paginator, setPaginator] = useState({
    [EPagination.PER_PAGE]: DEFAULT_PER_PAGE,
    [EPagination.CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    [EPagination.TOTAL]: DEFAULT_TOTAL,
    [EPagination.TOTAL_PAGES]: DEFAULT_TOTAL_PAGES,
  });

  const searchValue = useMemo(() => {
    return search;
  }, [search]);

  // MARK: Functions
  const { configuration, isLoading } = useGetSelectedConfiguration({
    [EFilters.SEARCH]: searchValue,
    is_active: 1,
    [EFilters.PAGE]: paginator[EPagination.CURRENT_PAGE],
    [EFilters.PER_PAGE]: paginator[EPagination.PER_PAGE],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleProxy = async (data: any) => {
    const formData = {
      is_active: false,
      client_db_table_ids: [data.id],
    };

    try {
      const response = await api.post(API_ROUTES.configurationMassUpdate, {}, formData);
      if (response) {
        success({ title: "Success", message: response?.data?.message });

        invalidate(KEY_CONFIGURATION_GET_ALL_SELECTED);
      }
    } catch (err) {
      error({ title: "Error" });
    }
  };
  const onPageChange = (newPage: number) => {
    setPaginator((prev) => {
      return { ...prev, [EPagination.CURRENT_PAGE]: newPage };
    });
  };

  const resetFilters = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  // MARK: Effects
  // Set initial pagination from backend
  useEffect(() => {
    if (!configuration || configuration?.length === 0) return;
    setPaginator(configuration?.pagination);
  }, [configuration]);

  // MARK: Renders
  if (isLoading) return <TableSkeleton />;

  return (
    <section>
      <PageHeader
        title="Configuration"
        additionalHeaderContent={
          <Button onClick={open} className="filled" leftSection={<Plus className="h-5 w-5 text-white" />}>
            Add favorite
          </Button>
        }
      />

      <Filters onReset={resetFilters}>
        <form>
          <TextInput
            defaultValue={search}
            onChange={(event) => setSearch(event?.currentTarget?.value)}
            placeholder="Search by table name"
            variant="unstyled"
          />
        </form>
      </Filters>

      <div className="w-full">
        <CustomTable
          columns={CONFIGURATION_TABLE_HEADER}
          onPageChange={onPageChange}
          hasFolderStructure
          paginator={configuration.pagination}
          tableData={configuration.data}
          handleProxy={(data) => handleProxy(data)}
          entity={EEntity.CONFIGURATION}
        />
      </div>

      <AddFavoriteConfiguration opened={opened} close={close} />
    </section>
  );
};

export { Configuration };
