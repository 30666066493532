import { PropsWithChildren, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// Constants
import { CLIENT_ROUTES } from "@constants/";

// Utils
import { info } from "@utils/";

type PublicRouteProps = PropsWithChildren & { isAuthenticated: boolean };

function PublicRoute({ isAuthenticated }: PublicRouteProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      info({ title: "You are already logged in." });
      navigate(CLIENT_ROUTES.root);
    }
  }, [navigate, isAuthenticated]);

  return <Outlet />;
}

export { PublicRoute };
