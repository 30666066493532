import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

// Functions
import { deleteClassification as deleteClassificationApi, getAllClasifications, getOneClassification } from "@api/";

// Constants
import { KEY_CLASSIFICATION_GET_ALL } from "@constants/";

// Utils
import { error, success } from "@utils/";

// Hooks
import { useInvalidate } from "@hooks/";

// Enums
import { EFilters } from "@enums/";

// Type
import type { TSearch } from "@_types/";

export const useGetAllClassifications = ({ search, page, per_page }: TSearch) => {
  const classificationPayload = useMemo(() => {
    return {
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
      },
    };
  }, [page, per_page, search]);

  const {
    data: classifications,
    isLoading: isClassificationLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_CLASSIFICATION_GET_ALL, classificationPayload], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getAllClasifications(classificationPayload), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true,
  });

  return { classifications, isLoading: isClassificationLoading || isFetching };
};

export const useGetOneClassification = ({ id }: { id: string | undefined }) => {
  const {
    data: classification,
    isLoading: isClassificationLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_CLASSIFICATION_GET_ALL, id],
    queryFn: () => getOneClassification(id!),
    initialData: [],
    enabled: !!id,
  });

  return { classification, isLoading: isClassificationLoading || isFetching };
};

export const useDeleteClassification = () => {
  const { invalidate } = useInvalidate();

  const deleteClassification = async (data: { id: string }) => {
    try {
      const response = await deleteClassificationApi(data?.id);
      if (response) {
        success({ message: response?.data?.message });
        invalidate(KEY_CLASSIFICATION_GET_ALL);
      }
    } catch (err) {
      error({ title: "Something went wrong" });
    }
  };

  return { deleteClassification };
};
