import type { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

// Pages
import { ErrorScreen } from "@pages/";

// Utils
import { error } from "@utils/";

type ErrorWrapperProps = { children: ReactNode };

const ErrorWrapper = ({ children }: ErrorWrapperProps) => {
  return (
    <ErrorBoundary
      fallback={<ErrorScreen />}
      onError={(err) => {
        error({ title: "Oops!", message: err.message });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export { ErrorWrapper };
