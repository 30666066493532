// Utils
import { api, removeEmptyFromObject } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

// Types
import { TFolderFilter } from "@_types/";

// Enums

const getAllClasifications = async ({ filters }: { filters?: TFolderFilter[] | TFolderFilter }) => {
  const data = removeEmptyFromObject({
    ...filters,
  });

  const response = await api.get(API_ROUTES.classifications, data);

  return response?.data;
};

const getOneClassification = async (id: string) => {
  const response = await api.get(`${API_ROUTES.classifications}/${id}`, {});
  return response?.data;
};

const createClassification = async (data: unknown) => {
  const response = await api.post(API_ROUTES.classifications, {}, data);
  return response?.data;
};

const updateClassification = async (id: string, data: unknown) => {
  const response = await api.patch(`${API_ROUTES.classifications}/${id}`, {}, data);
  return response?.data;
};

const deleteClassification = async (id: string) => {
  const response = await api.destroy(`${API_ROUTES.classifications}/${id}`);
  return response;
};

export { getAllClasifications, getOneClassification, createClassification, updateClassification, deleteClassification };
