import { Button, Tooltip } from "@mantine/core";
import { RefreshCcw } from "lucide-react";
import { ReactNode } from "react";

type TFilterProps = {
  onReset: () => void;
  children: ReactNode;
};
const Filters = ({ children, onReset }: TFilterProps) => {
  return (
    <section className="flex h-12 w-full items-center border-b border-t-0 border-braytron-lightGray bg-white">
      <div className="flex h-full w-12 items-center justify-center border-r border-braytron-lightGray">
        <Tooltip label="Refresh" color="gray">
          <Button onClick={onReset} className="group" variant="transparent" size="compact-sm">
            <RefreshCcw className="cursor-pointer text-braytron-50 hover:text-braytron-70" />
          </Button>
        </Tooltip>
      </div>

      <div className="w-full px-md">{children}</div>
    </section>
  );
};

export { Filters };
