import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

// Functions
import {
  deleteBenefit as deleteBenefitApi,
  getAllBenefitsFolder,
  getAllBenefitsFromFolder,
  getBenefitsNoPagination,
  getOneBenefit,
} from "@api/";

// Constants
import { KEY_BENEFIT_GET_ALL, KEY_BENEFITS_GET_ALL_NO_PAGINATION, KEY_GET_ALL_BENEFITS_FOLDERS } from "@constants/";

// Utils
import { error, success } from "@utils/";

// Hooks
import { useInvalidate } from "@hooks/";

// Enums
import { EFilters } from "@enums/";

// Type
import type { TSearch } from "@_types/";

export const useGetAllBenefits = ({ search, page, per_page }: TSearch) => {
  const { folderId } = useParams();

  const benefitPayload = useMemo(() => {
    return {
      folderId: folderId,
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
      },
    };
  }, [page, per_page, search, folderId]);

  const {
    data: benefits,
    isLoading: isBenefitsLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_BENEFIT_GET_ALL, benefitPayload], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getAllBenefitsFromFolder(benefitPayload), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: !!folderId, // Ne pozivaj ako nema folderId
  });

  return { benefits, isLoading: isBenefitsLoading || isFetching };
};

export const useGetBenefitsNoPagination = () => {
  const {
    data: benefits,
    isLoading: isBenefitLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_BENEFITS_GET_ALL_NO_PAGINATION], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getBenefitsNoPagination(), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true,
  });

  return { benefits, isBenefitDropdownLoading: isBenefitLoading || isFetching };
};

export const useGetOneBenefit = ({ id }: { id: string | undefined }) => {
  const {
    data: benefit,
    isLoading: isBenefitLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_BENEFIT_GET_ALL, id],
    queryFn: () => getOneBenefit(id!),
    initialData: [],
    enabled: !!id,
  });

  return { benefit, isLoading: isBenefitLoading || isFetching };
};

export const useGetBenefitFolders = ({ search, page, per_page }: TSearch) => {
  const foldersPayload = useMemo(() => {
    return {
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
      },
    };
  }, [page, per_page, search]);

  const {
    data: folders,
    isLoading: isFoldersLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_GET_ALL_BENEFITS_FOLDERS, foldersPayload],
    queryFn: () => getAllBenefitsFolder(foldersPayload),
    initialData: [],
  });

  return { folders, isLoading: isFoldersLoading || isFetching };
};

export const useDeleteBenefit = () => {
  const { invalidate } = useInvalidate();

  const deleteBenefit = async (data: { id: string }) => {
    try {
      const response = await deleteBenefitApi(data?.id);
      if (response) {
        success({ message: response?.data?.message });
        invalidate(KEY_BENEFIT_GET_ALL);
      }
    } catch (err) {
      error({ title: "Something went wrong" });
    }
  };

  return { deleteBenefit };
};
