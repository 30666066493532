import { icons } from "lucide-react";

type IconName = keyof typeof icons;

interface IconProps {
  name: IconName;
  className?: string;
}

const Icon = ({ name, className }: IconProps) => {
  const LucideIcon = icons[name];

  return <LucideIcon className={className} />;
};

export { Icon };
