import { Route, Routes } from "react-router-dom";

// Pages
import { NotFound } from "@pages/";
import { AddRequirement } from "./AddRequirement";
import { AllRequirements } from "./AllRequirements";
import { EditRequirement } from "./EditRequirement";
import { RequirementFolders } from "./RequirementFolders";

// Pages
const RequirementsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RequirementFolders />} />
      <Route path="/folder/:folderId" element={<AllRequirements />} />
      <Route path="/folder/:folderId/add" element={<AddRequirement />} />
      <Route path="/folder/:folderId/edit/:requirementId" element={<EditRequirement />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { RequirementsRoutes };
