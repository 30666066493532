import { Route, Routes } from "react-router-dom";

// Pages
import { AppRouter, Login } from "@pages/";

// Constants
import { CLIENT_ROUTES } from "@constants/";

// Layouts
import { ProtectedRoute, PublicRoute } from "@layouts/";

// Hooks
import { useAuth } from "@hooks/";

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
        <Route path={CLIENT_ROUTES.login} element={<Login />} />
      </Route>

      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/*" element={<AppRouter />} />
      </Route>
    </Routes>
  );
};

export { App };
