export enum EScheduleType {
  HOURLY = 1,
  DAILY = 2,
  MONTHLY = 3,
  QUARTERLY = 4,
  HALF_A_YEAR = 5,
  YEARLY = 6,
}

export const allScheduleTypes = [
  {
    label: "Hourly",
    value: EScheduleType.HOURLY.toString(),
  },
  {
    label: "Daily",
    value: EScheduleType.DAILY.toString(),
  },
  {
    label: "Monthly",
    value: EScheduleType.MONTHLY.toString(),
  },
  {
    label: "Quarterly",
    value: EScheduleType.QUARTERLY.toString(),
  },
  {
    label: "Half a year",
    value: EScheduleType.HALF_A_YEAR.toString(),
  },
  {
    label: "Yearly",
    value: EScheduleType.YEARLY.toString(),
  },
];
