import { ActionIcon, Button, Divider, Flex, Stack, Textarea, TextInput, Tooltip } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import { Plus, Save, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

// Components
import { FormSkeleton, LoadingOverlay, PageHeader, VariableModal } from "@components/";

// Constants
import { createBenefit, updateBenefit, useGetOneBenefit } from "@api/";

// Utils
import { success } from "@utils/";

// Constants
import { LABEL_TITLE, LABEL_XML, MIN_THREE_CHARACTERS, NOT_EMPTY } from "@constants/";

// Enums
import { ESize } from "@enums/";

enum EBenefitFormFields {
  NAME = "name",
  MONDAY_APIS = "monday_apis",
  SQL_QUERIES = "sql_queries",
  VARIABLES = "variables",
  XML = "xml",
}

type TVariableOption = {
  name: string;
  key: string;
};

const schema = z.object({
  [EBenefitFormFields.NAME]: z.string({ required_error: NOT_EMPTY }).min(3, { message: MIN_THREE_CHARACTERS }),
  [EBenefitFormFields.XML]: z.string().nullish(),
  [EBenefitFormFields.MONDAY_APIS]: z
    .array(
      z.object({
        link: z.string(),
      }),
    )
    .optional(),
  [EBenefitFormFields.SQL_QUERIES]: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .optional(),
  [EBenefitFormFields.VARIABLES]: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .optional(),
});

type TBenefits = z.infer<typeof schema>;

type TBenefitFormValues = {
  [EBenefitFormFields.NAME]: string;
  [EBenefitFormFields.XML]: string;
  [EBenefitFormFields.MONDAY_APIS]: { link: string; key: string; graphQL: { link: string; key: string } }[];
  [EBenefitFormFields.SQL_QUERIES]: { name: string; key: string }[];
  [EBenefitFormFields.VARIABLES]: { name: string; key: string }[];
};

const BenefitsForm = ({ edit }: { edit: boolean }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [variableOptions, setVariableOptions] = useState<TVariableOption[]>([]);
  const { folderId, benefitId } = useParams();
  const navigate = useNavigate();

  // In case of edit, call getOne benefit
  const { benefit, isLoading } = useGetOneBenefit({
    id: benefitId,
  });

  const form = useForm<TBenefitFormValues>({
    mode: "uncontrolled",
    initialValues: {
      [EBenefitFormFields.NAME]: "",
      [EBenefitFormFields.XML]: "",
      [EBenefitFormFields.MONDAY_APIS]: [],
      [EBenefitFormFields.SQL_QUERIES]: [],
      [EBenefitFormFields.VARIABLES]: [],
    },
    validate: zodResolver(schema),
    onValuesChange: (values) => {
      // eslint-disable-next-line no-console

      setVariableOptions(values[EBenefitFormFields.VARIABLES]);
    },
  });

  const onSubmit = async (values: TBenefits) => {
    setIsSubmitting(true);
    const { name, monday_apis, sql_queries, variables, xml } = values;

    if (edit) {
      const response = await updateBenefit(benefitId!, {
        [EBenefitFormFields.NAME]: name,
        [EBenefitFormFields.MONDAY_APIS]: monday_apis,
        [EBenefitFormFields.SQL_QUERIES]: sql_queries,
        [EBenefitFormFields.VARIABLES]: variables,
        [EBenefitFormFields.XML]: xml,
        folder_id: parseInt(folderId!),
      });

      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/benefits/folder/${folderId}`);
      }
    } else {
      const response = await createBenefit({
        [EBenefitFormFields.NAME]: name,
        [EBenefitFormFields.MONDAY_APIS]: monday_apis,
        [EBenefitFormFields.SQL_QUERIES]: sql_queries,
        [EBenefitFormFields.VARIABLES]: variables,
        [EBenefitFormFields.XML]: xml,
        folder_id: parseInt(folderId!),
      });

      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/benefits/folder/${folderId}`);
      }
    }
  };

  const removeVariableFromQueries = (variableName: string, variableIndex: number) => {
    form.removeListItem(EBenefitFormFields.VARIABLES, variableIndex);
    const variablePattern = new RegExp(`\\[\\[${variableName}\\]\\]`, "g"); // Create a regex pattern to match [[variableName]]

    // Get current form values
    const formData = form.getValues();

    // Update XML queries
    const updatedXMLQuery = formData.xml.replace(variablePattern, "");

    // Update SQL queries
    const updatedSqlQueries = formData.sql_queries.map((item) => {
      const updatedSql = item.name.replace(variablePattern, "");
      return { ...item, name: updatedSql };
    });

    // Update Monday APIs links
    const updatedMondayApis = formData.monday_apis.map((item) => {
      const updatedLink = item.link.replace(variablePattern, "");
      const updatedGraphQLLink = item.graphQL.link.replace(variablePattern, "");
      return {
        ...item,
        link: updatedLink,
        graphQL: {
          ...item.graphQL,
          link: updatedGraphQLLink,
        },
      };
    });

    // Set the updated values back to the form
    form.setValues({
      ...formData,
      sql_queries: updatedSqlQueries,
      monday_apis: updatedMondayApis,
      xml: updatedXMLQuery,
    });
  };

  /**
   * If form is in edit mode
   * Populate data
   */
  useEffect(() => {
    if (!edit && !benefit) return;
    form.setValues(benefit?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefit, edit]);

  if (isLoading && edit) return <FormSkeleton />;

  return (
    <form
      className="relative"
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <LoadingOverlay visible={isSubmitting} />
      <PageHeader
        title={edit ? "Edit benefit" : "Add benefit"}
        showBackBtn
        additionalHeaderContent={
          <Button type="submit" className="filled" leftSection={<Save className="h-5 w-5 text-white" />}>
            Save
          </Button>
        }
      />

      <section className="flex w-full flex-col-reverse p-md pt-xl lg:flex-row">
        <div className="flex w-full flex-col gap-y-md lg:w-3/5 lg:pr-lg">
          <TextInput label={LABEL_TITLE} {...form.getInputProps(EBenefitFormFields.NAME)} />
          {/* XML */}
          <Flex w="100%" align="center" justify="center">
            <TextInput
              key={form.key(`${EBenefitFormFields.XML}`)}
              w="100%"
              label={LABEL_XML}
              {...form.getInputProps(EBenefitFormFields.XML)}
            />
            <div className="mt-5">
              <VariableModal
                isSingle
                form={form}
                variablesArray={variableOptions}
                parentIndex={0}
                parentArrayName={EBenefitFormFields.XML}
                parentArrayKeyLocation={EBenefitFormFields.XML}
              />
            </div>
          </Flex>
          <Divider my={ESize.MD} />

          {/* SQL */}
          <div className="w-full">
            <div className="flex items-center">
              <p className="mr-md">SQL queries</p>
              <Tooltip label="Add link" color="gray">
                <ActionIcon
                  onClick={() =>
                    form.insertListItem(EBenefitFormFields.SQL_QUERIES, {
                      name: "",
                      key: randomId(),
                    })
                  }
                  variant="outline"
                  size="sm"
                >
                  <Plus size="1rem" />
                </ActionIcon>
              </Tooltip>
            </div>
            <article className="mt-md flex w-full flex-wrap">
              {form.getValues().sql_queries.map((sqlItem, index) => {
                return (
                  <div key={sqlItem.key} className="mb-md w-full">
                    <div className="flex items-center">
                      <Textarea
                        label="SQL query"
                        resize="vertical"
                        className="w-full"
                        placeholder="Enter SQL query"
                        key={form.key(`${EBenefitFormFields.SQL_QUERIES}.${index}.name`)}
                        {...form.getInputProps(`${EBenefitFormFields.SQL_QUERIES}.${index}.name`)}
                      />

                      <div className="ml-2 mt-4 flex items-center justify-start">
                        <VariableModal
                          form={form}
                          variablesArray={variableOptions}
                          parentIndex={index}
                          parentArrayName={EBenefitFormFields.SQL_QUERIES}
                          parentArrayKeyLocation="name"
                        />
                        <Tooltip label="Remove link" color="gray">
                          <Button
                            type="button"
                            variant="transparent"
                            size="compact-sm"
                            onClick={() => form.removeListItem(EBenefitFormFields.SQL_QUERIES, index)}
                          >
                            <Trash className="h-5 w-5 text-red" />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })}
            </article>
          </div>
          <Divider my={ESize.MD} />

          {/* MONDAY API LINKS */}
          <div className="w-full">
            <div className="flex items-center">
              <p className="mr-md">Monday APIs</p>
              <Tooltip label="Add link" color="gray">
                <ActionIcon
                  onClick={() =>
                    form.insertListItem(EBenefitFormFields.MONDAY_APIS, {
                      link: "",
                      key: randomId(),
                      graphQL: {
                        link: "",
                        key: randomId(),
                      },
                    })
                  }
                  variant="outline"
                  size="sm"
                >
                  <Plus size="1rem" />
                </ActionIcon>
              </Tooltip>
            </div>
            <article className="mt-md flex w-full flex-wrap">
              {form.getValues().monday_apis.map((apiLink, index) => {
                return (
                  <div key={apiLink.key} className="mb-xl w-full">
                    <Stack>
                      <div className="flex items-center">
                        <Textarea
                          resize="vertical"
                          label="Link"
                          className="w-full"
                          placeholder="/api/v1/test"
                          key={form.key(`${EBenefitFormFields.MONDAY_APIS}.${index}.link`)}
                          {...form.getInputProps(`${EBenefitFormFields.MONDAY_APIS}.${index}.link`)}
                        />
                        <div className="ml-2 mt-4 flex items-center justify-start">
                          <VariableModal
                            form={form}
                            variablesArray={variableOptions}
                            parentIndex={index}
                            parentArrayName={EBenefitFormFields.MONDAY_APIS}
                            parentArrayKeyLocation="link"
                          />
                          <Tooltip label="Remove link" color="gray">
                            <Button
                              type="button"
                              variant="transparent"
                              size="compact-sm"
                              onClick={() => form.removeListItem(EBenefitFormFields.MONDAY_APIS, index)}
                            >
                              <Trash className="h-5 w-5 text-red" />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Textarea
                          resize="vertical"
                          label="GraphQL Query"
                          className="w-full"
                          placeholder="GraphQL query"
                          key={form.key(`${EBenefitFormFields.MONDAY_APIS}.${index}.graphQL.link`)}
                          {...form.getInputProps(`${EBenefitFormFields.MONDAY_APIS}.${index}.graphQL.link`)}
                        />
                        <div className="ml-2 mt-4 flex items-center justify-start">
                          <VariableModal
                            form={form}
                            variablesArray={variableOptions}
                            parentIndex={index}
                            parentArrayName={EBenefitFormFields.MONDAY_APIS}
                            parentArrayKeyLocation="graphQL"
                            deeplyNestedKeyName="link"
                          />
                        </div>
                      </div>
                    </Stack>
                  </div>
                );
              })}
            </article>
          </div>
        </div>

        {/* VARIABLES */}
        <div className="mb-lg w-full border-braytron-lightGray lg:w-2/5 lg:border-l lg:pl-lg">
          <div className="flex w-full items-center">
            <p className="mr-md">Variables</p>
            <Tooltip label="Add variable" color="gray">
              <ActionIcon
                onClick={() =>
                  form.insertListItem(EBenefitFormFields.VARIABLES, {
                    name: "",
                    key: randomId(),
                  })
                }
                variant="outline"
                size="sm"
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Tooltip>
          </div>
          <article className="mt-md flex w-full flex-wrap">
            {form.getValues().variables.map((variable, index) => {
              return (
                <div key={variable.key} className="mb-md w-full lg:pr-md">
                  <div className="flex items-center">
                    <TextInput
                      className="w-full"
                      placeholder="Enter variable name"
                      key={form.key(`${EBenefitFormFields.VARIABLES}.${index}.name`)}
                      {...form.getInputProps(`${EBenefitFormFields.VARIABLES}.${index}.name`)}
                    />
                    <Tooltip label="Remove variable" color="gray">
                      <Button
                        type="button"
                        variant="transparent"
                        size="compact-sm"
                        onClick={() => removeVariableFromQueries(variable.name, index)}
                      >
                        <Trash className="h-5 w-5 text-red/70 hover:text-red" />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </article>
        </div>
      </section>
    </form>
  );
};

export { BenefitsForm };
