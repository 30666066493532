import { createTheme } from "@mantine/core";

// Constants
import { COLORS } from "@constants/";

export const theme = createTheme({
  primaryColor: "braytron",
  fontFamily: "Roboto, sans-serif",
  white: "#FDFFFC", // Baby powder
  black: "#2d2d2e", // dark mode color
  defaultRadius: "sm",
  autoContrast: true,
  colors: {
    braytron: [
      COLORS["braytron-10"],
      COLORS["braytron-20"],
      COLORS["braytron-30"],
      COLORS["braytron-40"],
      COLORS["braytron-50"],
      COLORS["braytron-60"],
      COLORS["braytron-70"],
      COLORS["braytron-80"],
      COLORS["braytron-90"],
      COLORS["braytron-100"],
    ],
  },
});
