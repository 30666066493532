/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-ignore
function removeEmptyFromObject(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!value !== null && value !== undefined && value !== "") {
      //@ts-ignore
      acc[key] = typeof value === "object" ? removeEmptyFromObject(value) : value;
    }
    return acc;
  }, {});
}

export { removeEmptyFromObject };
