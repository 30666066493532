import { Button, Flex, Menu, Tooltip } from "@mantine/core";
import { CircleAlert, Trash } from "lucide-react";

type TConfirmPopupProps = {
  title: string;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void; // Function with event parameter
};

const ConfirmPopup = ({ title, handleDelete }: TConfirmPopupProps) => {
  return (
    <Menu width={320} position="bottom" withArrow shadow="lg">
      <Menu.Target>
        <Tooltip label="Delete" color="gray">
          <Button variant="transparent" size="compact-sm" className="group">
            <Trash className="h-4 w-4 text-braytron-textDark group-hover:text-red" />
          </Button>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label className="mb-md flex w-full items-center">
          <CircleAlert className="mr-2 h-4 w-4 text-red" />
          <p className="text-sm">{title}</p>
        </Menu.Label>
        <Flex align="center" justify="center">
          <Menu.Item variant="transparent" className="text-center text-braytron-textDark">
            No
          </Menu.Item>
          <Menu.Item
            variant="outline"
            className="text-center text-braytron-textDark"
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(event);
            }}
          >
            Yes
          </Menu.Item>
        </Flex>
      </Menu.Dropdown>
    </Menu>
  );
};

export { ConfirmPopup };
