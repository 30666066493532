import { Button, Pagination, Tooltip } from "@mantine/core";
import { Folder, Pencil } from "lucide-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";

// Types
import { TPaginator } from "@_types/";

// Enums
import { EEntity } from "@enums/";
import { EditFolderModal } from "./EditFolderModal";

type TData = {
  id: number;
  name: string;
  folder_type: EEntity;
};

type TAllFoldersProps = {
  entity: EEntity;
  data: TData[];
  paginator: TPaginator;
  onPageChange?: (arg0: number) => void;
};

const ShowAllFolders = ({ entity, data, paginator, onPageChange }: TAllFoldersProps) => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [editData, setEditData] = useState<TData>();

  const folderURL = useCallback(
    (folderID: number) => {
      switch (entity) {
        case EEntity.BENEFITS:
          navigate(`/benefits/folder/${folderID}`);
          break;
        case EEntity.REQUIREMENTS:
          navigate(`/requirements/folder/${folderID}`);
          break;
        default:
          return "#";
      }
    },
    [entity, navigate],
  );

  const handleEdit = (item: TData) => {
    setEditData(item);
    open();
  };

  if (!data)
    return (
      <section className="w-full p-md pt-xl">
        <article className="grid w-full grid-cols-1 gap-5 bg-white p-md md:grid-cols-2 xl:grid-cols-5">
          <p className="px-4 py-3">No data to display</p>
        </article>
      </section>
    );

  return (
    <section className="w-full p-md pt-xl">
      {/* FOLDER DATA */}
      <article className="grid w-full grid-cols-1 gap-5 bg-white p-md md:grid-cols-2 xl:grid-cols-5">
        {data.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => folderURL(item.id)}
              className="flex h-28 cursor-pointer flex-col justify-between rounded-md border-[1px] border-gray bg-offwhite p-md hover:bg-braytron-lightGray"
            >
              <div className="flex w-full justify-between">
                <p>
                  <Folder className="text-braytron-darkGray" />
                </p>
                <div>
                  <Tooltip label="Edit" color="gray">
                    <Button
                      className="group"
                      variant="transparent"
                      size="compact-sm"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents click from propagating to the parent div
                        handleEdit(item);
                      }}
                    >
                      <Pencil className="h-4 w-4 text-braytron-textDark group-hover:text-braytron-80" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <p> {item.name}</p>
            </div>
          );
        })}
      </article>

      {/* PAGINATION */}
      <div className="flex w-full items-center justify-center border-t border-braytron-lightGray bg-white py-2">
        <Pagination
          value={paginator?.current_page}
          onChange={(value: number) => {
            onPageChange && onPageChange(value);
          }}
          total={paginator?.total_pages}
        />
      </div>

      <EditFolderModal opened={opened} close={close} entity={entity} data={editData!} />
    </section>
  );
};

export { ShowAllFolders };
