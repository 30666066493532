import { Button, Modal, MultiSelect, Stack } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";
import { useEffect, useMemo, useState } from "react";
import { useDebouncedState } from "@mantine/hooks";

// Enums
import { EFilters, EPagination, ESize } from "@enums/";

// Constants
import {
  API_ROUTES,
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_TOTAL,
  DEFAULT_TOTAL_PAGES,
  KEY_CONFIGURATION_GET_ALL,
  KEY_CONFIGURATION_GET_ALL_SELECTED,
} from "@constants/";

// Hooks
import { useInvalidate } from "@hooks/";

// API
import { useGetConfiguration } from "@api/";

// Utils
import { api, success } from "@utils/";

enum EAddFavoriteFormFields {
  IS_ACTIVE = "is_active",
  CLIENT_DB_TABLE_IDS = "client_db_table_ids",
}
const schema = z.object({
  [EAddFavoriteFormFields.IS_ACTIVE]: z.boolean(),
  [EAddFavoriteFormFields.CLIENT_DB_TABLE_IDS]: z.array(z.number()),
});

interface ISelectConfigurationModalProps {
  opened: boolean;
  close: () => void;
}

const AddFavoriteConfiguration = ({ opened, close }: ISelectConfigurationModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [search, setSearch] = useDebouncedState("", DEFAULT_DEBOUNCE_TIME);
  const [paginator] = useState({
    [EPagination.PER_PAGE]: DEFAULT_PER_PAGE,
    [EPagination.CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    [EPagination.TOTAL]: DEFAULT_TOTAL,
    [EPagination.TOTAL_PAGES]: DEFAULT_TOTAL_PAGES,
  });
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allData, setAllData] = useState([]);
  const { invalidate } = useInvalidate();

  const searchValue = useMemo(() => search, [search]);

  const { configuration } = useGetConfiguration({
    [EFilters.SEARCH]: searchValue,
    is_active: 0,
    [EFilters.PAGE]: paginator[EPagination.CURRENT_PAGE],
    [EFilters.PER_PAGE]: 100,
  });

  const form = useForm({
    initialValues: {
      [EAddFavoriteFormFields.IS_ACTIVE]: true,
      [EAddFavoriteFormFields.CLIENT_DB_TABLE_IDS]: [] as number[],
    },
    validate: zodResolver(schema),
  });

  useEffect(() => {
    if (configuration?.data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newData = configuration.data.map((item: any) => ({
        ...item,
        value: item.id.toString(),
        label: item.table_column,
      }));
      // @ts-expect-error TODO: Tipizacija settera
      setAllData((prevData) => {
        // Combine previous data with new data, avoiding duplicates
        const combinedData = [...new Map([...prevData, ...newData].map((item) => [item.value, item])).values()];
        return combinedData;
      });
    }
  }, [configuration]);

  useEffect(() => {
    // Sync form with selected items
    form.setValues((values) => ({
      ...values,
      [EAddFavoriteFormFields.CLIENT_DB_TABLE_IDS]: selectedItems.map((item) => parseInt(item, 10)),
    }));
  }, [selectedItems]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const handleChange = (values: string[]) => {
    setSelectedItems(values); // Update selected items
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    setIsSubmitting(true);

    const response = await api.post(API_ROUTES.configurationMassUpdate, {}, values);

    if (response) {
      success({ title: "Success", message: response?.data?.message });
      close();
      setSelectedItems([]);
      setAllData([]);
      invalidate(KEY_CONFIGURATION_GET_ALL);
      invalidate(KEY_CONFIGURATION_GET_ALL_SELECTED);
    }

    setIsSubmitting(false);
  };

  return (
    <Modal radius={ESize.MD} centered size={ESize.MD} opened={opened} onClose={close} title="Select favorite">
      <Stack gap={ESize.MD}>
        <form
          className="flex h-full w-full flex-col gap-y-md"
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <MultiSelect
            required
            clearable
            searchable
            data={allData} // Use combined data
            value={selectedItems} // Control value with selectedItems
            onChange={handleChange}
            w="100%"
            label="Database table and columns"
            placeholder="Select"
            onSearchChange={handleSearchChange}
          />
          <Button fullWidth type="submit" loading={isSubmitting}>
            Save
          </Button>
        </form>
      </Stack>
    </Modal>
  );
};

export { AddFavoriteConfiguration };
