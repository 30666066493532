import { Button, Modal, Stack, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";

// Enums
import { EEntity, ESize } from "@enums/";

// Constants
import {
  API_ROUTES,
  KEY_GET_ALL_BENEFITS_FOLDERS,
  KEY_GET_ALL_REQUIREMENTS_FOLDERS,
  MIN_THREE_CHARACTERS,
  NOT_EMPTY,
} from "@constants/";

// Utils
import { api, success } from "@utils/";

// Hooks
import { useInvalidate } from "@hooks/";
import { useState } from "react";

type TFolderCreate = z.infer<typeof schema>;

enum ECreateFolderFormFields {
  NAME = "name",
  FOLDER_TYPE = "folder_type",
}

const schema = z.object({
  [ECreateFolderFormFields.NAME]: z.string({ required_error: NOT_EMPTY }).min(3, { message: MIN_THREE_CHARACTERS }),
  [ECreateFolderFormFields.FOLDER_TYPE]: z.number(),
});

interface ICreateFolderModalProps {
  opened: boolean;
  entity: EEntity;
  close: () => void;
}

const CreateFolderModal = ({ opened, close, entity }: ICreateFolderModalProps) => {
  const { invalidate } = useInvalidate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm({
    initialValues: {
      [ECreateFolderFormFields.NAME]: "",
      [ECreateFolderFormFields.FOLDER_TYPE]: entity,
    },
    validate: zodResolver(schema),
  });

  const onSubmit = async (values: TFolderCreate) => {
    setIsSubmitting(true);
    const response = await api.post(API_ROUTES.folders, {}, values);

    if (response) {
      success({ title: "Success", message: response?.data?.message });

      if (entity === EEntity.BENEFITS) {
        invalidate(KEY_GET_ALL_BENEFITS_FOLDERS);
      } else {
        invalidate(KEY_GET_ALL_REQUIREMENTS_FOLDERS);
      }

      setIsSubmitting(false);
      close();
    }
  };

  return (
    <Modal radius={ESize.MD} centered size={ESize.MD} opened={opened} onClose={close} title="Create Folder">
      <Stack gap={ESize.MD}>
        <form
          className="flex h-full w-full flex-col gap-y-md"
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <TextInput label="Folder Name" {...form.getInputProps(ECreateFolderFormFields.NAME)} />

          <Button loading={isSubmitting} fullWidth type="submit">
            Create
          </Button>
        </form>
      </Stack>
    </Modal>
  );
};

export { CreateFolderModal };
