import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import notFound from "@images/notFound.png";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-[80dvh] flex-col items-center justify-center">
      <div className="space-y-md text-center">
        <img className="mx-auto w-[450px]" src={notFound} />
        <h1 className="text-2xl font-bold tracking-tight sm:text-3xl">Page not found</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export { NotFound };
