import type { ReactNode } from "react";

type TVisibleProps = {
  when: boolean;
  children: ReactNode;
};

const Visible = ({ when, children }: TVisibleProps) => {
  if (!when) return null;
  return <>{children}</>;
};

export { Visible };
