// Components
import { Avatar } from "@components/";

// Hooks
import { useUser } from "@hooks/";

// Enums
import { ESize } from "@enums/";

const SidebarFooter = () => {
  const { user } = useUser();

  return (
    <div className="flex h-16 w-full items-center gap-x-2 border-t border-gray px-sm">
      <Avatar initials src={""} size={ESize.MD} name={user?.name} />
      <div className="pl-1">
        <p className="text-sm">{user?.name}</p>
        <p className="text-xs text-braytron-darkGray">{user?.email}</p>
      </div>
    </div>
  );
};

export { SidebarFooter };
