import { createContext, PropsWithChildren } from "react";

// User Request
import { initialUser, useGetUser } from "@api/";

export const UserContext = createContext<{
  user: typeof initialUser;
  isLoading: boolean;
}>({
  user: initialUser,
  isLoading: true,
});

function UserProvider({ children }: PropsWithChildren) {
  const { data: user, isLoading: isUserLoading, isFetching } = useGetUser();

  return (
    <UserContext.Provider value={{ user: user?.data ?? initialUser, isLoading: isUserLoading || isFetching }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider };
