import { showNotification } from "@mantine/notifications";

export const info = ({ title = "Info!", message = "" }) => {
  showNotification({
    withCloseButton: true,
    autoClose: 3000,
    title: title,
    message: message ?? null,
    loading: false,
  });
};
