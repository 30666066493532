import { AuthContext } from "@contexts/";
import { useContext } from "react";

export function useAuth() {
  const { isAuthenticated, id, token, dispatch } = useContext(AuthContext);

  if (isAuthenticated === undefined || token === undefined || id === undefined)
    throw new Error("Auth Context is undefined");

  return { isAuthenticated, token, id, dispatch };
}
