import { Routes, Route } from "react-router-dom";

// Pages
import { AllBenefits } from "./AllBenefits";
import { EditBenefit } from "./EditBenefit";
import { NotFound } from "@pages/";
import { AddBenefit } from "./AddBenefit";
import { BenefitFolders } from "./BenefitFolders";

const BenefitRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BenefitFolders />} />
      <Route path="/folder/:folderId" element={<AllBenefits />} />
      <Route path="/folder/:folderId/add" element={<AddBenefit />} />
      <Route path="/folder/:folderId/edit/:benefitId" element={<EditBenefit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { BenefitRoutes };
