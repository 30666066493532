import { Avatar as MantineAvatar } from "@mantine/core";

// Enums
import { ESize } from "@enums/";

// Utilities
import { getInitials } from "@utils/";

type AvatarProps = {
  initials?: boolean;
  name: string;
  src?: string;
  size: ESize;
};

const Avatar = ({ initials, name, src, size }: AvatarProps) => {
  const { firstName, lastName } = getInitials(name);

  const firstLetterOfName = firstName[0];
  const firstLetterOfLastName = lastName[0];

  if (initials && !src)
    return (
      <MantineAvatar size={size} color="initials">
        {firstLetterOfName}
        {firstLetterOfLastName}
      </MantineAvatar>
    );

  return (
    <MantineAvatar size={size} src={src} color="initials">
      {firstLetterOfName}
      {firstLetterOfLastName}
    </MantineAvatar>
  );
};

export { Avatar };
