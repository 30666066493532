import { Button, Divider, MultiSelect, Select, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Save } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

// Components
import { DND, FormSkeleton, PageHeader, Visible } from "@components/";

// Enums
import { EClassificationType, EEntity, ESize } from "@enums/";

// Api
import {
  createClassification,
  updateClassification,
  useGetCustomersNoPagination,
  useGetOneClassification,
  useGetRanksNoPagination,
} from "@api/";

// Enums
import { allClassificationTypes } from "@enums/";

// Utils
import { isEmpty, success } from "@utils/";

// Constants
import { MIN_THREE_CHARACTERS, NOT_EMPTY } from "@constants/";

enum EClassificationFormFields {
  TITLE = "title",
  CUSTOMER_IDS = "customer_ids",
  RANKS = "ranks",
  CLASSIFICATION_TYPE = "classification_type",
}

interface TransformedRank {
  rank_id: number; // or string, depending on your actual data type
  position: number;
}

const schema = z.object({
  [EClassificationFormFields.TITLE]: z.string({ required_error: NOT_EMPTY }).min(3, { message: MIN_THREE_CHARACTERS }),
  [EClassificationFormFields.CUSTOMER_IDS]: z.array(z.string() || z.number()),
  [EClassificationFormFields.RANKS]: z.array(z.string()).nonempty(),
  [EClassificationFormFields.CLASSIFICATION_TYPE]: z.string() || z.number(),
});

type TClassification = z.infer<typeof schema>;

type ClassificationFormProps = { edit: boolean };
const ClassificationForm = ({ edit }: ClassificationFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { classificationId } = useParams();
  const { customers, isCustomersLoading } = useGetCustomersNoPagination();
  const { ranks, isRankLoading } = useGetRanksNoPagination();
  const [rankHierarchy, setRankHierarchy] = useState([]);
  const navigate = useNavigate();

  // In case of edit, call getOne benefit
  const { classification, isLoading } = useGetOneClassification({
    id: classificationId,
  });

  const form = useForm({
    initialValues: {
      [EClassificationFormFields.TITLE]: "",
      [EClassificationFormFields.CUSTOMER_IDS]: [],
      [EClassificationFormFields.RANKS]: [],
      [EClassificationFormFields.CLASSIFICATION_TYPE]: "",
    },
    // TODO: Schema
    // validate: zodResolver(schema),
  });

  const RANK_VALUES = useMemo(() => {
    return (
      rankHierarchy.map((item: { value: string; id: number }) => {
        return item?.id ? item?.id.toString() : item?.value;
      }) ?? ""
    );
  }, [rankHierarchy]);

  const DND_RANK_VALUES = useMemo(() => {
    return (
      rankHierarchy.map((item: { name: string; id: number; value: string }) => {
        return {
          label: item?.name,
          value: item?.id ? item?.id.toString() : item?.value,
        };
      }) ?? ""
    );
  }, [rankHierarchy]);

  const ranksFromClassification = useMemo(() => {
    return (
      classification?.data?.ranks?.map((item: { name: string; id: number }) => {
        return {
          ...item,
          label: item.name,
          value: item.id.toString(),
        };
      }) ?? []
    );
  }, [classification?.data?.ranks]);

  const customersDropdownData = useMemo(() => {
    return customers?.data?.map((item: { name: string; id: number }) => {
      return {
        ...item,
        label: item.name,
        value: item.id.toString(),
      };
    });
  }, [customers]);

  const ranksDropdownData = useMemo(() => {
    const oldRanks = edit ? ranksFromClassification : [];
    const apiRanks =
      ranks?.data?.map((item: { name: string; id: number }) => {
        return {
          ...item,
          label: item.name,
          value: item.id.toString(),
        };
      }) ?? [];

    return [...apiRanks, ...oldRanks];
  }, [ranks, edit, ranksFromClassification]);

  const onSubmit = async (values: TClassification) => {
    const transformedRanks: TransformedRank[] = [];
    rankHierarchy.forEach((singleRank: { id: number; value: number; position: number }, index: number) => {
      transformedRanks.push({
        rank_id: singleRank?.id ? singleRank?.id : singleRank?.value,
        position: index + 1,
      });
    });

    if (edit) {
      const response = await updateClassification(classificationId!, {
        [EClassificationFormFields.TITLE]: values.title,
        [EClassificationFormFields.CUSTOMER_IDS]: values.customer_ids.map(Number),
        [EClassificationFormFields.RANKS]: transformedRanks,
        [EClassificationFormFields.CLASSIFICATION_TYPE]: values.classification_type,
      });
      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/classifications/`);
      }
    } else {
      const response = await createClassification({
        [EClassificationFormFields.TITLE]: values.title,
        [EClassificationFormFields.CUSTOMER_IDS]: values.customer_ids.map(Number),
        [EClassificationFormFields.RANKS]: transformedRanks,
        [EClassificationFormFields.CLASSIFICATION_TYPE]: values.classification_type,
      });

      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/classifications/`);
      }
    }
  };

  /**
   * If form is in edit mode
   * Populate data
   */
  useEffect(() => {
    if (isEmpty(classification) || !edit) return;
    const { data } = classification;
    setRankHierarchy(data.ranks);

    const apiClassifications = data.ranks.map((item: { name: string; id: number }) => {
      return {
        ...item,
        id: item.id.toString(),
        value: item.id,
        label: item.name,
      };
    });

    form.setValues({
      [EClassificationFormFields.TITLE]: data.title,
      [EClassificationFormFields.CUSTOMER_IDS]: data.customers.map((customer: { id: number }) =>
        customer.id.toString(),
      ),
      // @ts-expect-error types
      [EClassificationFormFields.RANKS]: [...apiClassifications, ...(ranksFromClassification as never)],
      [EClassificationFormFields.CLASSIFICATION_TYPE]: data.classification_type.toString(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classification, edit]);

  if (isCustomersLoading || isRankLoading || isLoading) {
    return <FormSkeleton />;
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        // @ts-expect-error TODO: Tipizacija
        onSubmit(values);
      })}
    >
      <PageHeader
        showBackBtn
        title={edit ? "Edit Classification" : "Add Classification"}
        entity={EEntity.CLASSIFICATION}
        additionalHeaderContent={
          <Button
            loading={isSubmitting}
            type="submit"
            className="filled"
            leftSection={<Save className="h-5 w-5 text-white" />}
          >
            Save
          </Button>
        }
      />
      <section className="w-full p-md pt-xl">
        <div className="flex flex-col gap-x-3 md:flex-row">
          <TextInput
            className="mb-2 w-full md:w-1/2"
            withAsterisk
            label="Title"
            {...form.getInputProps(EClassificationFormFields.TITLE)}
          />
          <Select
            className="mb-2 w-full md:w-1/2"
            clearable
            withAsterisk
            label="Classification type"
            data={allClassificationTypes}
            {...form.getInputProps(EClassificationFormFields.CLASSIFICATION_TYPE)}
          />
        </div>

        <MultiSelect
          className="mb-2"
          clearable
          data={customersDropdownData}
          // key={form.key(EClassificationFormFields.CUSTOMER_IDS)}
          label="Customers"
          limit={10}
          placeholder="Select"
          searchable
          withAsterisk
          {...form.getInputProps(EClassificationFormFields.CUSTOMER_IDS)}
        />

        <MultiSelect
          clearable
          data={ranksDropdownData}
          label="Ranks"
          required
          searchable
          defaultValue={RANK_VALUES}
          onChange={(values) => {
            const setter = values.map((value) => ranksDropdownData.find((item) => item.value === value));
            // @ts-expect-error  Tipizacija settera
            setRankHierarchy(setter);
          }}
        />

        <Visible when={form.values.classification_type === EClassificationType.HIERARCHY.toString()}>
          <Divider my={ESize.MD} />

          <div key={rankHierarchy.toString()}>
            <Title order={4} mb={ESize.LG}>
              Reorder hierarcy of ranks
            </Title>
            <DND data={edit ? DND_RANK_VALUES : rankHierarchy} setData={setRankHierarchy} />
          </div>
        </Visible>
      </section>
    </form>
  );
};

export { ClassificationForm };
