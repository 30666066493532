import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

// Functions
import { getAllFavouriteConfiguration, getCustomersNoPagination } from "@api/";

// Constants
import {
  KEY_CONFIGURATION_GET_ALL,
  KEY_CONFIGURATION_GET_ALL_SELECTED,
  KEY_CUSTOMERS_NO_PAGINATION,
} from "@constants/";

// Enums
import { EFilters } from "@enums/";

// Type
type TConfigSearch = {
  [EFilters.SEARCH]: string;
  [EFilters.PAGE]: number;
  [EFilters.PER_PAGE]: number;
  is_active: number;
};
export const useGetSelectedConfiguration = ({ search, is_active, page, per_page }: TConfigSearch) => {
  const configurationPayload = useMemo(() => {
    return {
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
        is_active: is_active,
      },
    };
  }, [page, per_page, search, is_active]);

  const {
    data: configuration,
    isLoading: isConfigurationLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_CONFIGURATION_GET_ALL_SELECTED, configurationPayload], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getAllFavouriteConfiguration(configurationPayload), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true, // Ne pozivaj ako nema folderId
  });

  return { configuration, isLoading: isConfigurationLoading || isFetching };
};

export const useGetConfiguration = ({ search, is_active, page, per_page }: TConfigSearch) => {
  const configurationPayload = useMemo(() => {
    return {
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
        is_active: is_active,
      },
    };
  }, [page, per_page, search, is_active]);

  const {
    data: configuration,
    isLoading: isConfigurationLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_CONFIGURATION_GET_ALL, configurationPayload], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getAllFavouriteConfiguration(configurationPayload), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true, // Ne pozivaj ako nema folderId
  });

  return { configuration, isLoading: isConfigurationLoading || isFetching };
};

export const useGetCustomersNoPagination = () => {
  const {
    data: customers,
    isLoading: isCustomersLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_CUSTOMERS_NO_PAGINATION], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getCustomersNoPagination(), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true,
  });

  return { customers, isCustomersLoading: isCustomersLoading || isFetching };
};
