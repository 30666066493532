/* eslint-disable @typescript-eslint/no-explicit-any */
import { Combobox, Input, InputBase, Text, useCombobox } from "@mantine/core";
import { useEffect, useState } from "react";

// Utils
import { isEmpty } from "@utils/";
import clsx from "clsx";

export function BenefitSelect({
  classes,
  form,
  index,
  data,
}: {
  classes: string;
  form: any;
  index: number;
  data: {
    label: string;
    value: string;
    variables: {
      label: string;
      value: string;
    }[];
  }[];
}) {
  const passedBenefits = form.getValues().benefits[index];
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [value, setValue] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const selectedOption = data.find((item) => item.value === value?.value);

  const options = data.map((item) => {
    return (
      <Combobox.Option
        // @ts-expect-error Unexpected typing, dunno why
        value={{
          label: item.label,
          value: item.value,
          variables: item.variables,
        }}
        key={item.value}
      >
        <Text size="sm">{item.label}</Text>
      </Combobox.Option>
    );
  });

  useEffect(() => {
    if (!isEmpty(passedBenefits)) {
      setValue({
        value: passedBenefits.id ? passedBenefits.id : passedBenefits.benefit_id,
        label: passedBenefits.name,
      });
    }
  }, [passedBenefits]);

  return (
    <div className={clsx(classes)}>
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
          setValue(
            val as unknown as {
              value: string;
              label: string;
            },
          );
          form.setFieldValue(`benefits.${index}.benefit_id`, (val as unknown as { value: string }).value);
          form.setFieldValue(`benefits.${index}.variable_values`, (val as unknown as { variables: string }).variables);
          combobox.closeDropdown();
        }}
      >
        <Combobox.Target>
          <InputBase
            label="Benefits"
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={() => combobox.toggleDropdown()}
            rightSectionPointerEvents="none"
            multiline
          >
            {selectedOption ? (
              <Text size="sm">{selectedOption.label as unknown as string}</Text>
            ) : (
              <Input.Placeholder>Select Benefit</Input.Placeholder>
            )}
          </InputBase>
        </Combobox.Target>
        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
}
