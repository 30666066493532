import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  MultiSelect,
  NumberInput,
  Select,
  Stack,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import { Plus, Save, Trash } from "lucide-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

// Components
import { BenefitSelect, FormSkeleton, PageHeader, Visible } from "@components/";

// Enums
import { allScheduleTypes, EScheduleType, ESize } from "@enums/";

// API
import {
  createRank,
  updateRank,
  useGetBenefitsNoPagination,
  useGetOneRank,
  useGetRequirementsNoPagination,
} from "@api/";

// Constants
import { MIN_THREE_CHARACTERS, NOT_EMPTY } from "@constants/";

// Utils
import { convertWithTimeToDayMonthYear, isEmpty, revertFromDayMonthYear, success } from "@utils/";

type RankFormProps = {
  edit: boolean;
};

enum ERankFormFields {
  NAME = "name",
  BENEFITS = "benefits",
  REQUIREMENT_IDS = "requirement_ids",
  SCHEDULE_TYPE = "schedule_type",
  SCHEDULE_VALUE = "schedule_value",
  SCHEDULE_INTERVAL_HOURS = "schedule_interval_hours",
}

const rankFormSchema = z.object({
  [ERankFormFields.NAME]: z.string({ required_error: NOT_EMPTY }).min(3, { message: MIN_THREE_CHARACTERS }),
  [ERankFormFields.SCHEDULE_TYPE]: z.string(),
  [ERankFormFields.SCHEDULE_VALUE]: z.preprocess(
    (input) => {
      if (typeof input === "string" || typeof input === "number") {
        return new Date(input);
      }
      return input;
    },
    z.instanceof(Date).refine((date) => !isNaN(date.getTime()), {
      message: "Invalid date",
    }),
  ),
  [ERankFormFields.REQUIREMENT_IDS]: z.array(z.string()).nonempty(),
  [ERankFormFields.SCHEDULE_INTERVAL_HOURS]: z.coerce
    .number()
    .min(1, { message: "Number must be at least 1" })
    .max(23, { message: "Number must be at most 23" })
    .nonnegative({ message: "Number must be positive" })
    .refine((value) => value !== null || value !== undefined, {
      message: NOT_EMPTY,
    }),
});

type TRankFormSchema = z.infer<typeof rankFormSchema> & { benefits: { id: string }[] };

const RankForm = ({ edit }: RankFormProps) => {
  const { requirements, isRequirementDropdownLoading } = useGetRequirementsNoPagination();
  const { benefits, isBenefitDropdownLoading } = useGetBenefitsNoPagination();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const requirementsDropdownData = useMemo(() => {
    return requirements?.data?.map((item: { name: string; id: number }) => {
      return {
        ...item,
        label: item.name,
        value: item.id.toString(),
      };
    });
  }, [requirements]);

  const benefitsDropdownData = useMemo(() => {
    return benefits?.data?.map((item: { name: string; id: number; variables: { name: string }[] }) => {
      return {
        ...item,
        label: item.name,
        value: item.id.toString(),
        variable_values: item?.variables,
      };
    });
  }, [benefits]);

  // In case of edit, call getOne benefit
  const { rank, isLoading } = useGetOneRank({
    id: id,
  });

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      [ERankFormFields.NAME]: "",
      [ERankFormFields.BENEFITS]: [],
      [ERankFormFields.REQUIREMENT_IDS]: [],
      [ERankFormFields.SCHEDULE_TYPE]: allScheduleTypes[0].value,
      [ERankFormFields.SCHEDULE_VALUE]: "",
      [ERankFormFields.SCHEDULE_INTERVAL_HOURS]: 1,
    },
    // validate: zodResolver(rankFormSchema),
  });

  const onSubmit = async (values: TRankFormSchema) => {
    if (edit) {
      const response = await updateRank(id!, {
        [ERankFormFields.NAME]: values.name,
        [ERankFormFields.REQUIREMENT_IDS]: values.requirement_ids.map(Number),
        [ERankFormFields.BENEFITS]: values.benefits.map((benefit) => {
          return {
            ...benefit,
            benefit_id: benefit.id,
          };
        }),
        [ERankFormFields.SCHEDULE_TYPE]: +values.schedule_type,
        [ERankFormFields.SCHEDULE_VALUE]: convertWithTimeToDayMonthYear(values.schedule_value),
        [ERankFormFields.SCHEDULE_INTERVAL_HOURS]: values.schedule_interval_hours,
      });
      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/ranks/`);
      }
    } else {
      const response = await createRank({
        [ERankFormFields.NAME]: values.name,
        [ERankFormFields.REQUIREMENT_IDS]: values.requirement_ids.map(Number),
        [ERankFormFields.BENEFITS]: values.benefits,
        [ERankFormFields.SCHEDULE_TYPE]: +values.schedule_type,
        [ERankFormFields.SCHEDULE_VALUE]: convertWithTimeToDayMonthYear(values.schedule_value),
        [ERankFormFields.SCHEDULE_INTERVAL_HOURS]: values.schedule_interval_hours,
      });

      if (response) {
        setIsSubmitting(false);
        success({ title: "Success", message: response?.message });
        navigate(`/ranks/`);
      }
    }
  };

  useEffect(() => {
    if (isEmpty(rank)) return;

    if (edit) {
      const { data } = rank;
      form.setValues({
        [ERankFormFields.NAME]: data.name,
        [ERankFormFields.REQUIREMENT_IDS]: data.requirements.map((requirement: { id: number }) =>
          requirement.id.toString(),
        ),
        [ERankFormFields.BENEFITS]: data.benefits.map((benefit: { id: number }) => {
          return {
            ...benefit,
            id: benefit.id.toString(),
          };
        }),
        [ERankFormFields.SCHEDULE_TYPE]: data.schedule_type.toString(),
        [ERankFormFields.SCHEDULE_VALUE]: revertFromDayMonthYear(data.schedule_value) as unknown as string,
        [ERankFormFields.SCHEDULE_INTERVAL_HOURS]: data.schedule_interval_hours,
      });
    }
  }, [edit, rank]);

  if (isBenefitDropdownLoading || isRequirementDropdownLoading || (edit && isLoading)) {
    return <FormSkeleton />;
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values as unknown as TRankFormSchema);
      })}
    >
      <PageHeader
        showBackBtn
        title={edit ? "Edit Rank" : "Add Rank"}
        additionalHeaderContent={
          <Button
            type="submit"
            loading={isSubmitting}
            className="filled"
            leftSection={<Save className="h-5 w-5 text-white" />}
          >
            Save
          </Button>
        }
      />

      <section className="w-full p-md pt-md">
        <TextInput withAsterisk label="Title" placeholder="Rank title" {...form.getInputProps(ERankFormFields.NAME)} />

        <Divider my={ESize.LG} />

        <Stack w="100%">
          <Title order={4}>Requirements</Title>
          <MultiSelect
            withAsterisk
            clearable
            searchable
            data={requirementsDropdownData}
            w="100%"
            label="Select Requirements"
            placeholder="Select Requirement"
            key={form.key(ERankFormFields.REQUIREMENT_IDS)}
            {...form.getInputProps(ERankFormFields.REQUIREMENT_IDS)}
          />
        </Stack>

        <Divider my={ESize.LG} />

        <Stack>
          <Title order={4}>Schedule</Title>
          <section className="flex flex-col gap-3 md:flex-row">
            <Select
              data={allScheduleTypes}
              key={form.key(ERankFormFields.SCHEDULE_TYPE)}
              label="Schedule"
              required
              className="w-full md:w-1/3"
              {...form.getInputProps(ERankFormFields.SCHEDULE_TYPE)}
            />

            <DateTimePicker
              clearable
              label="Select Date"
              placeholder="Date input"
              required
              valueFormat="DD.MM.YYYY HH:mm"
              className="w-full md:w-1/3"
              key={form.key(ERankFormFields.SCHEDULE_VALUE)}
              {...form.getInputProps(ERankFormFields.SCHEDULE_VALUE)}
            />

            <Visible when={form.getValues()[ERankFormFields.SCHEDULE_TYPE] === EScheduleType.HOURLY.toString()}>
              <NumberInput
                label="Check by hour (1 - 23)"
                placeholder="Enter value"
                className="w-full md:w-1/3"
                min={1}
                max={23}
                {...form.getInputProps(ERankFormFields.SCHEDULE_INTERVAL_HOURS)}
              />
            </Visible>
          </section>
        </Stack>

        <Divider my={ESize.LG} />

        <Stack w="100%">
          <div className="flex w-full items-center">
            <Title order={4} className="mr-md">
              Benefits
            </Title>

            <Tooltip label="Add Benefit" color="gray">
              <ActionIcon
                onClick={() =>
                  form.insertListItem(ERankFormFields.BENEFITS, {
                    benefit_id: 0,
                    variable_values: [],
                    key: randomId(),
                  })
                }
                variant="outline"
                size="sm"
              >
                <Plus size="1rem" />
              </ActionIcon>
            </Tooltip>
          </div>
          {form.getValues()[ERankFormFields.BENEFITS].map((item, index: number) => {
            return (
              <Fragment
                key={
                  (
                    item as {
                      key: string;
                    }
                  ).key
                }
              >
                <Flex gap={ESize.MD} className="w-full" align="start">
                  <BenefitSelect
                    key={form.key(ERankFormFields.BENEFITS[index])}
                    classes="w-6/12"
                    data={benefitsDropdownData}
                    form={form}
                    index={index}
                  />
                  <div className="grid w-5/12 gap-y-2">
                    {/* @ts-expect-error Types */}
                    {form.getValues()[ERankFormFields.BENEFITS][index].variable_values.map((variable, varIndex) => (
                      <TextInput
                        key={variable.key}
                        w="100%"
                        label={variable.name}
                        placeholder="Enter variable value"
                        {...form.getInputProps(
                          `${ERankFormFields.BENEFITS}.${index}.variable_values.${varIndex}.value`,
                        )}
                      />
                    ))}
                  </div>
                  <Flex gap={ESize.MD} mt={"1.5rem"} className="flex w-1/12 justify-end">
                    <Visible when={form.getValues()[ERankFormFields.BENEFITS].length > 1}>
                      <Tooltip label="Remove benefit" color="gray">
                        <Button
                          type="button"
                          variant="transparent"
                          size="compact-sm"
                          onClick={() => form.removeListItem(ERankFormFields.BENEFITS, index)}
                        >
                          <Trash className="h-5 w-5 text-red/70 hover:text-red" />
                        </Button>
                      </Tooltip>
                    </Visible>
                  </Flex>
                </Flex>
              </Fragment>
            );
          })}
        </Stack>
      </section>
    </form>
  );
};

export { RankForm };
