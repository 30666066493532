import { Button } from "@mantine/core";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

const ErrorScreen = () => {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();
  const { reset: resetQuery } = useQueryErrorResetBoundary();

  return (
    <div className="flex h-[80dvh] flex-col items-center justify-center">
      <div className="space-y-md text-center">
        <h1 className="text-2xl font-bold tracking-tight sm:text-3xl">Unkown Error Occured</h1>
        <Button
          className="underline"
          onClick={() => {
            resetQuery();
            resetBoundary();
            navigate(-1);
          }}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export { ErrorScreen };
