// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmpty(value: any = []) {
  return (
    value === undefined ||
    value === null ||
    value?.length === 0 ||
    (typeof value === "object" && Object?.keys(value)?.length === 0) ||
    (typeof value === "string" && value?.trim()?.length === 0)
  );
}

export { isEmpty };
