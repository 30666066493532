import { Burger, AppShell as MantineAppShell } from "@mantine/core";

// Components
import { Logo } from "@components/";

type HeaderProps = {
  opened: boolean;
  toggle: () => void;
};

const Header = ({ opened, toggle }: HeaderProps) => {
  return (
    <MantineAppShell.Header p={16}>
      <div className="flex items-center justify-between">
        <Logo />
        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
      </div>
    </MantineAppShell.Header>
  );
};

export { Header };
