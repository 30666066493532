// Utils
import { api } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

// Types
import { IAuthLogin } from "@interfaces/";

const loginRequest = async (data: IAuthLogin) => {
  const response = await api.post(API_ROUTES.login, {}, data);
  return response;
};

export { loginRequest };
