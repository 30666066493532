import { Route, Routes } from "react-router-dom";

import { NotFound } from "@pages/";

// Pages
import { AddClassifications } from "./AddClassifications";
import { AllClassifications } from "./AllClassifications";
import { EditClassification } from "./EditClassifications";

const ClassificationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AllClassifications />} />
      <Route path="/add" element={<AddClassifications />} />
      <Route path="/edit/:classificationId" element={<EditClassification />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { ClassificationRoutes };
