// Utils
import { api, removeEmptyFromObject } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

// Types
import { TFolderFilter } from "@_types/";

const getAllFavouriteConfiguration = async ({ filters }: { filters?: TFolderFilter[] | TFolderFilter }) => {
  const data = removeEmptyFromObject({
    ...filters,
  });

  const response = await api.get(API_ROUTES.configuration, data);
  return response?.data;
};

const getCustomersNoPagination = async () => {
  const response = await api.get(`${API_ROUTES.customers}/all`, {});
  return response?.data;
};

export { getAllFavouriteConfiguration, getCustomersNoPagination };
