import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { rem, Text } from "@mantine/core";
import { useListState } from "@mantine/hooks";
import clsx from "clsx";
import { GripVertical } from "lucide-react";
import { Dispatch, SetStateAction, useEffect } from "react";
import classes from "./DND.module.css";

const DND = ({
  data,
  setData,
}: {
  data: { label: string; value: string }[];
  setData: Dispatch<SetStateAction<never[]>>;
}) => {
  const [state, handlers] = useListState(data);

  const items = state.map((item, index) => (
    <Draggable key={item.value} index={index} draggableId={item.value}>
      {(provided, snapshot) => (
        <div
          className={clsx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <GripVertical style={{ width: rem(18), height: rem(18) }} />
          </div>
          <div>
            <Text>{item.label}</Text>
            <Text c="dimmed" size="sm">
              Position: {index + 1}
            </Text>
          </div>
        </div>
      )}
    </Draggable>
  ));

  useEffect(() => {
    const stateCopy = JSON.parse(JSON.stringify(state));
    stateCopy.forEach((item: { position: number }, index: number) => {
      item.position = index + 1;
    });
    setData(stateCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        handlers.reorder({ from: source.index, to: destination?.index || 0 });
      }}
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DND };
