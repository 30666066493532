import { Button, Menu, Tooltip } from "@mantine/core";
import { CirclePlus } from "lucide-react";
import { useCallback } from "react";

// Components
import { Visible } from "./Visible";

// Types
import type { UseFormReturnType } from "@mantine/form";

// Enums
import { ESize } from "@enums/";

type TVariableModalProps = {
  isSingle?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturnType<any>;
  variablesArray: TVariableArray[];
  parentIndex: number;
  parentArrayName: string;
  parentArrayKeyLocation: string;
  deeplyNestedKeyName?: string;
};

type TVariableArray = {
  name: string;
  key: string;
};
const VariableModal = ({
  isSingle,
  form,
  variablesArray,
  parentIndex,
  parentArrayName,
  parentArrayKeyLocation,
  deeplyNestedKeyName,
}: TVariableModalProps) => {
  const addVariable = useCallback(
    (variableName: string, index: number, locationArray: string, keyName: string, deeplyNestedKeyName?: string) => {
      if (isSingle) {
        const currentLinkContent = form.getValues()[keyName];
        const updatedLinkContent = `${currentLinkContent} [[${variableName}]]`;

        form.setFieldValue(`${keyName}`, updatedLinkContent);
        return;
      }

      const currentLinkContent = deeplyNestedKeyName
        ? form.getValues()[locationArray][index][keyName][deeplyNestedKeyName]
        : form.getValues()[locationArray][index][keyName];
      const updatedLinkContent = `${currentLinkContent} [[${variableName}]]`;

      const setter = deeplyNestedKeyName
        ? `${locationArray}.${index}.${keyName}.${deeplyNestedKeyName}`
        : `${locationArray}.${index}.${keyName}`;

      form.setFieldValue(setter, updatedLinkContent);
    },
    [form],
  );

  return (
    <Menu width={320} position="bottom" withArrow shadow={ESize.LG}>
      <Menu.Target>
        <Tooltip label="Insert variable" color="gray">
          <Button type="button" variant="transparent" size="compact-xs">
            <CirclePlus className="h-5 w-5 text-braytron-textDark" />
          </Button>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label className="text-sm text-braytron-textDark/80">Available variables</Menu.Label>
        {variablesArray.map((variable) => {
          return (
            <Menu.Item
              key={variable.key}
              onClick={() => {
                addVariable(variable.name, parentIndex, parentArrayName, parentArrayKeyLocation, deeplyNestedKeyName);
              }}
            >
              <Visible when={!!variable.name}>
                <span>{variable?.name}</span>
              </Visible>
            </Menu.Item>
          );
        })}

        <Visible when={variablesArray.length === 0}>
          <span className="px-sm text-xs text-braytron-textDark/70">
            <i>No variables created</i>
          </span>
        </Visible>
      </Menu.Dropdown>
    </Menu>
  );
};

export { VariableModal };
