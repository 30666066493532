// MARK: Imports
import { Button, TextInput } from "@mantine/core";
import { Plus } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";

// Api
import { useGetRequirementFolders } from "@api/";

// Components
import { CreateFolderModal, Filters, PageHeader, ShowAllFolders, TableSkeleton } from "@components/";

// Constants
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PER_PAGE,
  DEFAULT_TOTAL,
  DEFAULT_TOTAL_PAGES,
} from "@constants/";

// Enums
import { EEntity, EFilters, EPagination } from "@enums/";

// Hooks
import { useDebouncedState, useDisclosure } from "@mantine/hooks";

const RequirementFolders = () => {
  // MARK: State

  const [search, setSearch] = useDebouncedState("", DEFAULT_DEBOUNCE_TIME);
  const [opened, { open, close }] = useDisclosure(false);
  const [paginator, setPaginator] = useState({
    [EPagination.PER_PAGE]: DEFAULT_PER_PAGE,
    [EPagination.CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    [EPagination.TOTAL]: DEFAULT_TOTAL,
    [EPagination.TOTAL_PAGES]: DEFAULT_TOTAL_PAGES,
  });

  const searchValue = useMemo(() => {
    return search;
  }, [search]);

  // MARK: Functions
  const { folders, isLoading } = useGetRequirementFolders({
    [EFilters.SEARCH]: searchValue,
    [EFilters.PAGE]: paginator[EPagination.CURRENT_PAGE],
    [EFilters.PER_PAGE]: paginator[EPagination.PER_PAGE],
  });

  const onPageChange = (newPage: number) => {
    setPaginator((prev) => {
      return { ...prev, page: newPage, current_page: newPage };
    });
  };

  const resetFilters = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  // MARK: Effects
  // Set initial pagination from backend
  useEffect(() => {
    if (!folders || folders?.length === 0) return;
    setPaginator(folders?.pagination);
  }, [folders]);

  // MARK: Renders
  if (isLoading) return <TableSkeleton />;

  return (
    <section>
      <PageHeader
        title="Requirements folders"
        additionalHeaderContent={
          <Button onClick={open} className="filled" leftSection={<Plus className="h-5 w-5 text-white" />}>
            Create Folder
          </Button>
        }
      />

      <Filters onReset={resetFilters}>
        <form>
          <TextInput
            defaultValue={search}
            placeholder="Search by folder name..."
            onChange={(event) => setSearch(event?.currentTarget?.value)}
            variant="unstyled"
          />
        </form>
      </Filters>

      <ShowAllFolders
        entity={EEntity.REQUIREMENTS}
        data={folders?.data}
        paginator={paginator}
        onPageChange={onPageChange}
      />

      <CreateFolderModal opened={opened} close={close} entity={EEntity.REQUIREMENTS} />
    </section>
  );
};

export { RequirementFolders };
