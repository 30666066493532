// Enums

// Components
import { ClassificationForm } from "./ClassificationForm";

const AddClassifications = () => {
  return <ClassificationForm edit={false} />;
};

export { AddClassifications };
