/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropsWithChildren, createContext } from "react";
import { useImmerReducer } from "use-immer";

// Constants
import { LS_ID, LS_TOKEN } from "@constants/";

// Utils
import { getItem, removeItem, setItem } from "@utils/";

import { EActionAuthType, IAction, IInitialState, IState } from "@interfaces/";

const token: string | null = getItem(LS_TOKEN) ?? null;
const id: string | null = getItem(LS_ID) ?? null;

const initialState: IInitialState = {
  token: token !== null ? token : null,
  id: id !== null ? id : null,
  isAuthenticated: token !== null,
  dispatch: () => {},
};

function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case EActionAuthType.LOGIN:
      setItem(LS_TOKEN, action.payload.token);
      setItem(LS_ID, (action.payload as any).data?.id);
      return {
        isAuthenticated: true,
        token: action.payload.token,
        id: (action.payload as any).data?.id,
      };
    case EActionAuthType.SIGNUP:
      setItem(LS_TOKEN, action.payload.token);
      setItem(LS_ID, (action.payload as any).data?.id);
      return {
        isAuthenticated: true,
        token: action.payload.token,
        id: (action.payload as any).data?.id,
      };
    case EActionAuthType.LOGOUT:
      removeItem(LS_TOKEN);
      removeItem(LS_ID);
      return {
        isAuthenticated: false,
        token: null,
        id: null,
      };
    default:
      return state;
  }
}

export const AuthContext = createContext(initialState);

function AuthProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useImmerReducer<IState, IAction>(reducer, initialState);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        token: state.token,
        id: state.id,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider };
