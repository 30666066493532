import { useQueryClient } from "@tanstack/react-query";

const useInvalidate = () => {
  const queryClient = useQueryClient();

  const invalidate = (queryKey: string) => {
    queryClient.invalidateQueries({
      queryKey: [queryKey],
    });
  };

  return { invalidate };
};

export { useInvalidate };
